<template>
  <div
    class="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end"
  >
    <div
      class="app-auth-background"
      :class="{
        'auth-background-light': !night,
        'auth-background-dark': night,
      }"
    ></div>
    <div v-if="!complete" class="app-auth-container">
      <!-- <div class="logo">
                  <a href="#"></a>
              </div> -->
      <p class="auth-description">
        Please enter the email address associated with your account and we will
        send you a reset link
      </p>

      <div class="auth-credentials m-b-xxl">
        <div class="form-group col-6">
          <label for="signInEmail" class="form-label">{{
            $t("login.email_address")
          }}</label>
          <input
            v-model="email"
            type="email"
            class="form-control"
            id="signInEmail"
            aria-describedby="signInEmail"
            placeholder="example@varsanpr.com"
            name="username"
          />
        </div>
      </div>
      <p v-show="message" class="text-danger">{{ message }}</p>
      <div class="auth-submit">
        <button
          type="submit"
          @click="sendReset"
          :disabled="loading || email.length < 3"
          class="btn btn-primary"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm m-r-xxs"
          ></span>
          <span>Send me a Link</span>
        </button>
      </div>
    </div>
    <div v-else class="app-auth-container">
      <p class="auth-description">
        If the email address is correct, we will send you a link to your email.
        Please check your inbox and follow the instructions to reset your
        password.
      </p>

      <button class="btn btn-sm btn-primary" @click="$router.push('/login')">
        Back to Login
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Forgot",
  data() {
    return {
      loading: false,
      message: "",
      complete: false,
      email: "",
    };
  },
  mounted() {
    console.log(this.night);
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    night() {
      return this.$store.state.auth.status.dark;
    },
  },
  methods: {
    sendReset() {
      axios
        .post(`https://api.varsanpr.com/api/auth/reset`, {
          email: this.email,
        })
        .then((response) => {
          this.complete = true;
        })
        .catch((error) => {
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message =
              "There was an issue with your request. Please try again later.";
          }
        });
    },
  },
};
</script>

<style scoped>
.app-auth-background {
  height: 100vh;
}

.auth-background-light {
  background: url("../../../public/images/vars_logo_white.png") no-repeat;
  background-size: 75%;
  background-position: center;
}
.auth-background-dark {
  background: url("../../../public/images/vars_logo_black.png") no-repeat;
  background-size: 75%;
  background-position: center;
}
</style>
